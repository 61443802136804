import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useNextUserSearchParamsFilters } from "../useSearchParamsFilters";

import { isThereNoNextUser } from "~/helpers/error/errorHelpers";
import { useNextRecommendedUser } from "~/hooks/useApi/useNextRecommandedUser";
import {
  CareManagerActions,
  useNextRecommendedUserActions
} from "~/hooks/useApi/useNextRecommendedUserActions";
import { NextStepURLParams } from "~/typing/carePortalTypes";

export const useNavigateToNextUser = ({
  timeOfRankingOfCurrentUser,
  onNavigationCompleted
}: {
  timeOfRankingOfCurrentUser: string;
  onNavigationCompleted: (nextUserId: string) => void;
}) => {
  const [userIdSavedForLater, setUserIdSavedForLater] = useState<
    string | null
  >();
  const [isNavigating, setIsNavigating] = useState(false);

  const { user_id = "" } = useParams<NextStepURLParams>();
  const navigate = useNavigate();

  const { programFilters, taskFilters } = useNextUserSearchParamsFilters();

  const {
    nextUserInfo,
    isFetching: fetchingNextUser,
    isError: isFetchingNextUserError,
    invalidate: fetchNextUser,
    error: fetchingNextUserError
  } = useNextRecommendedUser({
    carePriorities: taskFilters,
    programCatalogItemIds: programFilters.map(
      (program) => program.programCatalogItemId
    )
  });

  const { registerActionForRecommendedUser } = useNextRecommendedUserActions({
    userId: user_id
  });

  const navigateToNextUser = () => {
    setIsNavigating(true);
    fetchNextUser();
  };

  useEffect(() => {
    if (isThereNoNextUser(fetchingNextUserError)) {
      navigate("/tasks/no-next-user");
      setIsNavigating(false);
      return;
    }
  }, [fetchingNextUserError]);

  useEffect(() => {
    const handleNavigation = async () => {
      if (nextUserInfo?.userId.toString() === user_id && !userIdSavedForLater) {
        if (userIdSavedForLater) return;

        await registerActionForRecommendedUser({
          careManagerAction: CareManagerActions.CMA_SAVE_FOR_LATER,
          carePriorityType: undefined,
          timeOfRanking: timeOfRankingOfCurrentUser,
          userId: user_id
        });
        setUserIdSavedForLater(user_id);
        fetchNextUser();
      } else {
        setUserIdSavedForLater(null);
        navigate(`/tasks/${nextUserInfo?.userId}${location.search}`);
        setIsNavigating(false);
        onNavigationCompleted(nextUserInfo?.userId);
      }
    };

    if (!isNavigating || fetchingNextUser) return;

    if (!nextUserInfo) return;

    handleNavigation();
  }, [
    nextUserInfo,
    isNavigating,
    user_id,
    fetchingNextUser,
    fetchingNextUserError,
    isFetchingNextUserError
  ]);

  return {
    navigateToNextUser,
    isNavigating
  };
};
