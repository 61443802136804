import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { UserDetails } from "~/typing/sidekickTypes";

export default function useUserDetail({
  programCatalogItemId,
  locale,
  userId
}: {
  programCatalogItemId: string;
  locale: string;
  userId: string;
}) {
  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/users/${userId}`;
  const { data, isError, isLoading, invalidate, error } = useApi<UserDetails>({
    path,
    queryKey: QueryKeyFactory.users.detail(
      programCatalogItemId,
      locale,
      userId
    ),
    disabled: !programCatalogItemId || !locale || !userId
  });

  return {
    userDetail: data,
    isError,
    isLoading,
    invalidate,
    error
  };
}
