import { DateTime } from "luxon";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./TaskHistoryItem.module.scss";

import Avatar from "~/components/avatar/Avatar";
import SkeletonCareManagerTask from "~/components/skeletons/SkeletonCareManagerTask";
import { humanizedTimeSince } from "~/helpers/date/dateHelpers";
import { convertEnumToTitleCase } from "~/helpers/string/stringHelpers";
import { useUserContextInfo } from "~/hooks/graphql/useCareManagerTasks";
import { CareManagerActions } from "~/hooks/useApi/useNextRecommendedUserActions";
import useUserDetail from "~/hooks/useApi/useUserDetail";
import { t } from "~/i18n";
import { CareManagerActionDto, CarePriorityType } from "~/typing/graphql/types";

type TaskHistoryItemProps = {
  log?: CareManagerActionDto;
};

const TaskHistoryItem = ({ log }: TaskHistoryItemProps) => {
  const {
    userContextInfo,
    isLoading: userContextLoading,
    isError: userContextError
  } = useUserContextInfo({ userId: log?.userId });

  const {
    userDetail,
    isLoading: userDetailsLoading,
    isError: userDetailsError
  } = useUserDetail({
    userId: log?.userId,
    locale: userContextInfo?.locale ?? "",
    programCatalogItemId: userContextInfo?.programCatalogItemId ?? ""
  });

  const getCareManagerActionDescription = () => {
    const stringData = {
      userName: userDetail?.user?.fullName
    };
    switch (log?.careManagerAction) {
      case CareManagerActions.CMA_NO_ACTION_NEEDED:
        return t(
          "nextStep.careManagerTasks.careManagerActionLogs.noActionNeeded",
          stringData
        );
      case CareManagerActions.CMA_SAVE_FOR_LATER:
        return t(
          "nextStep.careManagerTasks.careManagerActionLogs.saveForLater",
          stringData
        );
      case CareManagerActions.CMA_MARK_ROR_AS_DONE:
        return t(
          "nextStep.careManagerTasks.careManagerActionLogs.markPriorityAsDone",
          {
            ...stringData,
            carePriority: convertEnumToTitleCase(log?.carePriorityType)
          }
        );
      default:
        return t(
          "nextStep.careManagerTasks.careManagerActionLogs.unknownAction",
          stringData
        );
    }
  };

  const getActionLogDescription = () => {
    const stringData = {
      userName: userDetail?.user?.fullName
    };

    switch (log?.carePriorityType) {
      case CarePriorityType.NewMessage:
        return t(
          "nextStep.careManagerTasks.careManagerActionLogs.newMessage",
          stringData
        );
      case CarePriorityType.HighChurnRisk:
        return t(
          "nextStep.careManagerTasks.careManagerActionLogs.churnRisk",
          stringData
        );
      case CarePriorityType.WeeklyFeedback:
        return t(
          "nextStep.careManagerTasks.careManagerActionLogs.weeklyFeedback",
          stringData
        );
      case CarePriorityType.FirstWeek:
        return t(
          "nextStep.careManagerTasks.careManagerActionLogs.firstWeek",
          stringData
        );
      default:
        return getCareManagerActionDescription();
    }
  };

  if (userContextLoading || userDetailsLoading)
    return <SkeletonCareManagerTask />;

  if (userContextError || userDetailsError)
    return (
      <div className={styles.wrapper}>
        <div className={styles.information}>
          <Avatar
            size="lg"
            user={{
              fullName: userDetail?.user?.fullName,
              imageHref: userDetail?.user?.imageHref
            }}
          />
          <p className={styles.description}>
            Something went wrong while fetching the user details
          </p>
        </div>
        <p className={styles.date}>
          {`${DateTime.fromISO(log?.timeOfAction).toLocaleString(
            DateTime.DATETIME_MED
          )} / ${humanizedTimeSince(log?.timeOfAction)} ago`}
        </p>
      </div>
    );

  if (!log || !userContextInfo) return null;
  return (
    <Link to={`/tasks/${log.userId}`}>
      <div className={styles.wrapper}>
        <div className={styles.information}>
          <Avatar
            size="lg"
            user={{
              fullName: userDetail?.user?.fullName,
              imageHref: userDetail?.user?.imageHref
            }}
          />
          <p className={styles.description}>
            <Trans components={{ strong: <strong /> }}>
              {getActionLogDescription()}
            </Trans>
          </p>
        </div>
        <p className={styles.date}>
          {`${DateTime.fromISO(log?.timeOfAction).toLocaleString(
            DateTime.DATETIME_MED
          )} / ${humanizedTimeSince(log?.timeOfAction)} ago`}
        </p>
      </div>
    </Link>
  );
};

export default TaskHistoryItem;
