import { HelpCircle } from "@sidekickhealth/sidekick-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SupportWidget.module.scss";

import Modal from "../modal/Modal";
import ButtonR from "../robinDesignSystem/button/ButtonR";

const SupportButton = () => {
  const { t } = useTranslation();

  const [displaySupportModal, setDisplaySupportModal] = useState(false);

  return (
    <>
      <ButtonR
        className={styles.supportButton}
        onClick={() => setDisplaySupportModal(true)}
        label={t("support.support")}
        icon={<HelpCircle />}
      ></ButtonR>
      {displaySupportModal && (
        <Modal
          className={styles.modal}
          onClose={() => setDisplaySupportModal(false)}
          title={t("support.support")}
        >
          <p className={styles.disclaimer}>{t("support.disclaimer")} </p>
        </Modal>
      )}
    </>
  );
};

export default SupportButton;
