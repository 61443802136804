import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import CarePriorities from "./carePriorities/CarePriorities";
import NextStepChatView from "./chatView/NextStepChatView";
import Metrics from "./components/Metrics";
import NextStepUserHeader from "./components/NextStepUserHeader";
import { useCarePrioritiesToDisplay } from "./hooks/useCarePrioritiesToDisplay";
import { useNavigateToNextUser } from "./hooks/useNavigateToNextUser";
import { TaskPageContext } from "./TaskPage.context";
import styles from "./TaskPage.module.scss";
import { useNextUserSearchParamsFilters } from "./useSearchParamsFilters";

import { ReactComponent as ClockRewindIcon } from "~/assets/clock-rewind.svg";
import Close from "~/assets/svgComponents/Close";
import BackLink from "~/components/backLink/BackLink";
import Button from "~/components/button/Button";
import Layout from "~/components/layout/Layout";
import Modal from "~/components/modal/Modal";
import PulseLoader from "~/components/pulseLoader/PulseLoader";
import ButtonR from "~/components/robinDesignSystem/button/ButtonR";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { isUserDeletedError } from "~/helpers/error/errorHelpers";
import { convertEnumToTitleCase } from "~/helpers/string/stringHelpers";
import {
  useCalculateRankingScore,
  useUserContextInfo
} from "~/hooks/graphql/useCareManagerTasks";
import { useNextRecommendedUser } from "~/hooks/useApi/useNextRecommandedUser";
import { useNextRecommendedUserActions } from "~/hooks/useApi/useNextRecommendedUserActions";
import useProgram from "~/hooks/useApi/useProgram";
import useUserDetail from "~/hooks/useApi/useUserDetail";
import {
  useKeyboardShortcut,
  KeyboardShortcuts
} from "~/hooks/useKeyboardShortcut";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { NextStepURLParams } from "~/typing/carePortalTypes";

const TaskPage = () => {
  // Data and functions from hooks
  const { t } = useTranslation();
  const { user_id = "" } = useParams<NextStepURLParams>();

  const shortcuts = [
    {
      keys: KeyboardShortcuts.nextUser,
      callback: () => setShowTaskModal(true)
    },
    {
      keys: KeyboardShortcuts.confirm,
      callback: () => {
        if (showTaskModal) {
          navigateToNextUser();
        }
      }
    }
  ];

  useKeyboardShortcut(shortcuts);

  // Data and functions from custom made hooks
  const {
    programFilters,
    taskFilters,
    removeProgramFilter,
    removeTaskFilter
  } = useNextUserSearchParamsFilters();

  const {
    carePriorities,
    carePrioritiesLoading
  } = useCarePrioritiesToDisplay();

  const { navigateToNextUser, isNavigating } = useNavigateToNextUser({
    onNavigationCompleted: () => handleTrackNextStepTaken(nextUserInfo?.userId),
    timeOfRankingOfCurrentUser: carePriorities?.timeOfRanking || null
  });

  const { isPending: registerActionPending } = useNextRecommendedUserActions({
    userId: user_id
  });

  const { nextUserInfo } = useNextRecommendedUser({
    carePriorities: taskFilters,
    programCatalogItemIds: programFilters.map(
      (program) => program.programCatalogItemId
    )
  });
  const { trackNextStepTaken } = useAmplitudeTracking();
  const { recalculateRankingScore } = useCalculateRankingScore({
    userId: user_id
  });

  const {
    userContextInfo,
    isLoading: userContextLoading,
    isError: userContextError,
    status: userContextStatus
  } = useUserContextInfo({ userId: user_id });

  // Local state
  const [copiedMessage, setCopiedMessage] = useState("");
  const [copiedMessageId, setCopiedMessageId] = useState("");
  const [showTaskModal, setShowTaskModal] = useState(false);

  const {
    userDetail,
    isLoading: userDetailsLoading,
    isError: isUserDetailError,
    error
  } = useUserDetail({
    userId: user_id,
    programCatalogItemId: userContextInfo?.programCatalogItemId ?? "",
    locale: userContextInfo?.locale ?? ""
  });

  const { program, isLoading: programIsLoading } = useProgram({
    programCatalogItemId: userContextInfo?.programCatalogItemId ?? "",
    locale: userContextInfo?.locale ?? ""
  });

  useEffect(() => {
    if (userContextStatus === "error") {
      recalculateRankingScore({ userId: parseInt(user_id) });
    }
  }, [userContextInfo, userContextStatus]);

  useEffect(() => {
    setCopiedMessage("");
    setCopiedMessageId("");
    setShowTaskModal(false);
  }, [user_id]);

  useEffect(() => {
    if (isUserDetailError && isUserDeletedError(error)) {
      navigateToNextUser();
    }
  }, [isUserDetailError, error]);

  const handleTrackNextStepTaken = (nextUserId: string) => {
    const unfinishedCarePriorities =
      carePriorities?.priorities
        .filter((priority) =>
          carePriorities?.priorities.every(
            (completedPriority) => completedPriority?.type !== priority?.type
          )
        )
        .map((priority) => priority?.type as string) ?? [];

    const initialCarePrioritiesArray = carePriorities?.priorities.map(
      (priority) => priority?.type as string
    );

    trackNextStepTaken({
      carePriorities: initialCarePrioritiesArray ?? [],
      direction: "next",
      from: user_id,
      to: nextUserId,
      unfinishedCarePriorities
    });
  };

  const userAndProgramDataLoaded =
    !userContextLoading &&
    !programIsLoading &&
    !userDetailsLoading &&
    !isNaN(Number(user_id)) &&
    !carePrioritiesLoading &&
    !isNavigating;

  if (!userAndProgramDataLoaded)
    return (
      <Layout>
        <section className={styles.loadingWrapper}>
          <PulseLoader inverted />
        </section>
      </Layout>
    );

  if (userAndProgramDataLoaded && !userContextInfo) {
    return (
      <Layout>
        <section className={styles.centered}>
          <div className={styles.error}>
            <p>{t("nextStep.errors.userError")}</p>
            <Link to={"/programs"}>
              <Button>{t("nextStep.errors.returnToProgram")}</Button>
            </Link>
          </div>
        </section>
      </Layout>
    );
  }

  const handleClickNextUser = () => {
    if (carePriorities?.priorities.length === 0) {
      navigateToNextUser();
    } else {
      setShowTaskModal(true);
    }
  };

  const hasFiltersApplied = programFilters.length || taskFilters.length;

  return (
    <SentryErrorBoundary transactionName="CareManagerTask">
      <Layout>
        <TaskPageContext.Provider
          value={{
            nextUserInfo,
            userContext: userContextInfo,
            program: program,
            userDetailsLoading: userDetailsLoading,
            userDetails: userDetail,
            programLoading: programIsLoading,
            copiedMessage: copiedMessage,
            copiedMessageId: copiedMessageId,
            setCopiedMessage: setCopiedMessage,
            setCopiedMessageId: setCopiedMessageId,
            newNextStep: true
          }}
        >
          {userContextError && (
            <div className={styles.error}>
              <p>{t("nextStep.errors.fetchingUserInfo")}</p>
            </div>
          )}

          <div className={styles.header}>
            <BackLink url="/programs" text={t("nextStep.tasksOverview")} />
            {hasFiltersApplied ? (
              <div className={styles.filtersWrapper}>
                <p>{t("nextStep.filtersApplied")}:</p>
                <div className={styles.filters}>
                  {programFilters?.map((filter) => (
                    <ButtonR
                      label={filter.programCatalogItemName}
                      onClick={() =>
                        removeProgramFilter(program?.programCatalogItemId ?? "")
                      }
                      key={`next-user-filter-${filter.programCatalogItemId}`}
                      type="secondary"
                      size="sm"
                      className={styles.filter}
                      icon={<Close />}
                      iconPosition="trailing"
                    />
                  ))}
                  {taskFilters?.map((filter) => (
                    <ButtonR
                      label={convertEnumToTitleCase(filter)}
                      onClick={() => removeTaskFilter(filter)}
                      key={`next-user-filter-${filter}`}
                      type="secondary"
                      size="sm"
                      className={styles.filter}
                      icon={<Close />}
                      iconPosition="trailing"
                    />
                  ))}
                </div>
              </div>
            ) : null}

            <div className={styles.backNext}>
              <Link tabIndex={-1} to="/tasks/history">
                <ButtonR
                  label="Activity history"
                  icon={<ClockRewindIcon />}
                  type="tertiary"
                  size="sm"
                />
              </Link>
              <ButtonR
                type="secondary"
                onClick={handleClickNextUser}
                label={t("nextStep.nextUser")}
                size="sm"
              />
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.panel}>
              <NextStepUserHeader />
              <section className={styles.panelContent}>
                <CarePriorities
                  userContextInfo={userContextInfo}
                  userId={user_id}
                  carePriorities={carePriorities}
                  onAllCarePrioritiesCompleted={navigateToNextUser}
                />
                {userContextInfo && userDetail && (
                  <Metrics
                    locale={userContextInfo.locale}
                    programId={userContextInfo.programCatalogItemId}
                    userDetail={userDetail}
                  />
                )}
              </section>
            </div>
            <NextStepChatView />
          </div>
          {showTaskModal && (
            <Modal
              title={t("nextStep.modal.title")}
              onClose={() => setShowTaskModal(false)}
            >
              <>
                <p>{t("nextStep.modal.message1")}</p>
                <p>{t("nextStep.modal.message2")}</p>
                <div className={styles.modalButtons}>
                  <Button
                    className={`${styles.button} ${styles.cancel}`}
                    onClick={() => setShowTaskModal(false)}
                  >
                    {t("general.cancel")}
                  </Button>
                  <Button
                    className={`${styles.button} ${styles.confirm}`}
                    onClick={navigateToNextUser}
                    inverted
                    isLoading={registerActionPending}
                  >
                    {t("nextStep.continue")}
                  </Button>
                </div>
              </>
            </Modal>
          )}

          {(userContextError || isNaN(Number(user_id))) && (
            <div className={styles.error}>
              <p>{t("nextStep.errors.userError")}</p>
              <Link to={"/programs"}>
                <Button className={styles.button}>
                  {t("nextStep.errors.returnToProgram")}
                </Button>
              </Link>
            </div>
          )}
        </TaskPageContext.Provider>
      </Layout>
    </SentryErrorBoundary>
  );
};

export default TaskPage;
