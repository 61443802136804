import { QueryKey, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import useSessionHasExpired from "../useSessionHasExpired";

import api, { chatApi } from "~/api/api";
import { TimeInMs } from "~/constants/measurements";
import { displayErrorToast } from "~/helpers/toast/displayToast";

async function fetcher({ path, apiChoice }: { path: string; apiChoice: API }) {
  try {
    const response =
      apiChoice === "admin" ? await api.get(path) : await chatApi.get(path);

    return response;
  } catch (error) {
    throw error;
  }
}

type API = "chat-assistant" | "admin";

export default function useApi<T>({
  queryKey,
  path,
  staleTime,
  cacheTime = TimeInMs.Minute * 10,
  disabled,
  api = "admin",
  refetchInterval,
  modifyData,
  shouldRetry,
  refetchOnWindowFocus = true,
  errorToastMessage
}: {
  path: string;
  queryKey: QueryKey;
  staleTime?: number;
  cacheTime?: number;
  disabled?: boolean;
  api?: API;
  refetchInterval?: number | (() => number | false);
  modifyData?: (data?: T) => T;
  shouldRetry?: boolean;
  refetchOnWindowFocus?: boolean;
  errorToastMessage?: string;
}) {
  const queryClient = useQueryClient();

  const sessionExpired = useSessionHasExpired();

  const { data, isLoading, isError, error } = useQuery<AxiosResponse<T>>({
    queryKey,
    queryFn: () => fetcher({ path, apiChoice: api }),
    staleTime,
    enabled: !disabled && !sessionExpired,
    gcTime: cacheTime,
    refetchInterval,
    select: modifyData
      ? (res: AxiosResponse<T>) => ({ ...data, data: modifyData(res.data) })
      : undefined,
    retry: shouldRetry,
    refetchOnWindowFocus
  });

  if (isError && errorToastMessage) {
    displayErrorToast({ message: errorToastMessage, id: queryKey.join("-") });
  }

  return {
    data: data?.data,
    isError,
    error,
    isLoading,
    setManuallyInCache: (value?: T) =>
      queryClient.setQueryData(queryKey, { ...data, data: value }),
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey
      })
  };
}
