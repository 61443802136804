import { useMutation } from "@tanstack/react-query";
import { gql } from "graphql-request";

import { gqlClient } from "~/api/api";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import { ActOnUserDtoInput } from "~/typing/graphql/types";

type Props = {
  userId: string;
};

export enum NextUserAction {
  Send = "SEND",
  SaveForLater = "SAVE_FOR_LATER",
  Skip = "NO_ACTION_NEEDED"
}

export enum CareManagerActions {
  CMA_UNKNOWN = 0,
  CMA_MARK_AS_DONE = 1,
  CMA_NO_ACTION_NEEDED = 2,
  CMA_SAVE_FOR_LATER = 3,
  CMA_MARK_ROR_AS_DONE = 4
}

export const useNextRecommendedUserActions = ({ userId }: Props) => {
  const {
    mutateAsync: registerActionForRecommendedUser,
    isPending,
    isError
  } = useMutation({
    mutationKey: ["nextRecommendedUserAction", userId],
    mutationFn: (aou: ActOnUserDtoInput) => actOnUser(aou),
    onError: () => displayErrorToast({ message: t("errors.generic") })
  });

  return {
    registerActionForRecommendedUser,
    isPending,
    isError
  };
};

export const actOnUser = async (aou: ActOnUserDtoInput) => {
  const mutation = gql`
    mutation ActOnUser($actOnUser: ActOnUserDtoInput!) {
      actOnUser(actOnUser: $actOnUser)
    }
  `;

  return await gqlClient.request(mutation, {
    actOnUser: aou
  });
};
