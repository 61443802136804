import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";

import useGraphQLQuery from "./useGraphQLQuery";

import { QueryKeyFactory } from "../useApi/queryKeysFactory";
import useUser from "../useUser";

import {
  calculateRankingScore,
  getCareManagerTasks,
  getCarePriorities,
  getUserContextInfo,
  getUserSymptomsLoggedMetadata,
  markMessageSuggestionAsSent
} from "~/api/graphql/nextStep";
import { whitelistedCarePriorityTypes } from "~/constants/carePriorities";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import {
  CareManagerTaskDto,
  MutationCalculateRankingScoreArgs,
  MutationMarkMessageSuggestionAsSentArgs
} from "~/typing/graphql/types";

type Props = {
  userId: string;
};

export const useCareManagerTask = () => {
  const { user } = useUser();

  const { data, ...rest } = useGraphQLQuery({
    queryKey: QueryKeyFactory.users.careManagerTasks(user?.id ?? ""),
    queryFn: () => getCareManagerTasks(parseInt(user?.id ?? "")),
    disabled: !user?.id,
    modifyData: (data) => ({
      ...data,
      careManagerTaskDtos: data?.careManagerTaskDtos
        ?.map((careManagerTask) => {
          const filteredCarePriorities = careManagerTask?.carePriorities.filter(
            (carePriority) =>
              carePriority &&
              whitelistedCarePriorityTypes.includes(carePriority)
          );

          return filteredCarePriorities?.length
            ? ({
                ...careManagerTask,
                carePriorities: filteredCarePriorities
              } as CareManagerTaskDto)
            : null;
        })
        .filter((careManagerTask) => careManagerTask !== null)
    })
  });

  return {
    careManagerTasks: data,
    ...rest
  };
};

export const useUserContextInfo = ({
  userId,
  enabled = true
}: {
  userId: string;
  enabled?: boolean;
}) => {
  const { data, ...rest } = useGraphQLQuery({
    queryKey: QueryKeyFactory.users.userContextInfo(userId),
    queryFn: () => getUserContextInfo(userId),
    disabled: !userId || isNaN(Number(userId)) || !enabled,
    shouldRetry: 1
  });

  return {
    userContextInfo: data,
    ...rest
  };
};

export const useGetCarePriorities = (userId: string) => {
  const { data, ...rest } = useGraphQLQuery({
    queryKey: QueryKeyFactory.users.carePriorities(userId),
    queryFn: () => getCarePriorities(userId),
    disabled: !userId || isNaN(Number(userId)),
    shouldRetry: false,
    modifyData: (data) => ({
      ...data,
      priorities: data?.priorities.filter(
        (cp) => cp && whitelistedCarePriorityTypes.includes(cp.type)
      )
    })
  });

  return {
    carePriorities: data,
    ...rest
  };
};

export const useGetUserSymptomsLoggedMetadata = (userId: string) => {
  const { data, ...rest } = useGraphQLQuery({
    queryKey: QueryKeyFactory.users.userSymptomsLoggedMetadata(userId),
    queryFn: () => getUserSymptomsLoggedMetadata({ userId }),
    disabled: !userId || isNaN(Number(userId))
  });

  return {
    userSymptomsLoggedMetadata: data,
    ...rest
  };
};

export const useCalculateRankingScore = ({ userId }: Props) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: recalculateRankingScore,
    isPending,
    isError
  } = useMutation({
    mutationKey: ["calculateRankingScore", userId],
    mutationFn: (args: MutationCalculateRankingScoreArgs) =>
      calculateRankingScore(args),
    onError: () => displayErrorToast({ message: t("errors.generic") }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.users.userContextInfo(userId)
      });
    }
  });

  return {
    recalculateRankingScore,
    isPending,
    isError,
    enabled: !!userId && !isNaN(Number(userId))
  };
};

export const useMarkMessageSuggestionAsSent = () => {
  const { mutateAsync, isPending, isError } = useMutation({
    mutationKey: QueryKeyFactory.users.markMessageSuggestionAsSent(),
    mutationFn: (args: MutationMarkMessageSuggestionAsSentArgs) =>
      markMessageSuggestionAsSent(args),
    retry: false
  });

  return {
    markMessageSuggestionAsSent: mutateAsync,
    isPending,
    isError
  };
};
