export const isUserDeletedError = (error: Error | null) => {
  if (error?.message) {
    return error.message.includes("User has been deleted");
  }
  return false;
};

export const isThereNoNextUser = (error: Error | null) => {
  if (error?.message) {
    return error.message.includes("No next user found");
  }
  return false;
};
