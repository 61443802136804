import { DateTime } from "luxon";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useOutletContext, useParams } from "react-router-dom";

import Details from "./details/Details";
import InformationPageHeader from "./header/InformationPageHeader";
import HealthCards from "./healthCards/HealthCards";
import styles from "./InformationPage.module.scss";
import { InformationPageContext } from "./informationPageContext";
import CommunicationPreferences from "./modals/communicationPreferences/CommunicationPreferences";
import useBaseWeight from "./weightHistory/useBaseWeight";

import { UserPageRouteContext } from "../userPage/UserPage.context";

import PinnedNotes from "~/components/notes/PinnedNotes";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import SkeletonInformationPage from "~/components/skeletons/SkeletonInformationPage";
import getLanguageFromLocale from "~/helpers/getLanguageFromLocale";
import useCommunicationPreferences from "~/hooks/useApi/useCommunicationPreferences";
import useUserSettings from "~/hooks/useApi/useUserSettings";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";

const InformationPage = () => {
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();

  const {
    externalUser,
    setShowNotes: showAllNotes,
    leftDate,
    program,
    userDetail,
    externalUserType,
    isFinished,
    isLoading
  } = useOutletContext<UserPageRouteContext>();

  const {
    communicationPreferences,
    invalidate: invalidateCommunicationPreferences
  } = useCommunicationPreferences(user_id);

  const {
    weight: userBaseWeight,
    invalidate: invalidateBaseWeight
  } = useBaseWeight(program_id, locale, user_id);

  const { settings } = useUserSettings(user_id);

  const { trackUserInformationViewOpened } = useAmplitudeTracking();

  useEffect(() => {
    if (!program) return;
    trackUserInformationViewOpened();
  }, [program?.programCatalogItemId]);

  const language = getLanguageFromLocale(program?.locale);

  return (
    <SentryErrorBoundary transactionName="UserInformationPage">
      <div className={styles.InformationPage}>
        <Helmet title={t("general.information", "Information")} defer={false} />
        <InformationPageContext.Provider
          value={{
            baseWeight: userBaseWeight,
            invalidateBaseWeight,
            baseWeightEnabled: program?.useUserBaseWeight,
            program,
            userDetail,
            settings,
            externalUser,
            externalUserType,
            hasQuit: !!leftDate,
            isFinished
          }}
        >
          {isLoading ? (
            <SkeletonInformationPage />
          ) : (
            <>
              <InformationPageHeader />
              {userDetail && (
                <Details
                  userDetails={userDetail}
                  language={language}
                  leftDate={
                    leftDate ? DateTime.fromISO(leftDate).toLocaleString() : ""
                  }
                />
              )}
              <CommunicationPreferences
                optedOutOfSms={externalUser?.smsOptOutType === "STOP"}
                preferences={communicationPreferences}
                invalidatePreferences={invalidateCommunicationPreferences}
              />
              <PinnedNotes showAllNotes={() => showAllNotes(true)} />
              <HealthCards details={userDetail} />
            </>
          )}
        </InformationPageContext.Provider>
      </div>
    </SentryErrorBoundary>
  );
};

export default InformationPage;
