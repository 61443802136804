import { Link } from "react-router-dom";

import styles from "./NoNextUserPage.module.scss";

import Heading from "~/components/heading/Heading";
import Layout from "~/components/layout/Layout";
import ButtonR from "~/components/robinDesignSystem/button/ButtonR";

const NoNextUserPage = () => {
  return (
    <Layout>
      <div className={styles.wrapper}>
        <Heading level="h2" className={styles.title}>
          It looks like there is no next user to recommend
        </Heading>
        <Link to="/">
          <ButtonR size="lg" type="quaternary" label="Return to front page" />
        </Link>
      </div>
    </Layout>
  );
};

export default NoNextUserPage;
