import { createContext, Dispatch, SetStateAction } from "react";

import { NextUserDto, UserContextInfo } from "~/typing/graphql/types";
import { Program, UserDetails } from "~/typing/sidekickTypes";

type TaskPageContextProps = {
  userContext?: UserContextInfo;
  userDetailsLoading?: boolean;
  userDetails?: UserDetails;
  program?: Program;
  programLoading: boolean;
  copiedMessage?: string;
  copiedMessageId?: string;
  setCopiedMessage?: Dispatch<SetStateAction<string>>;
  setCopiedMessageId?: Dispatch<SetStateAction<string>>;
  newNextStep?: boolean;
  nextUserInfo?: NextUserDto;
};

export const TaskPageContext = createContext<TaskPageContextProps>(
  {} as TaskPageContextProps
);
